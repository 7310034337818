<template>
  <h1>{{ message }}</h1>
</template>

<script>
import { authActionTypes, authMutationsTypes } from '@/store/modules/auth'
import {
  quizzesMutationsTypes,
  quizzesActionsTypes,
} from '@/store/modules/quizzesModals'
const { checkConfirmByMail } = authActionTypes
const { isFirstLogin } = authMutationsTypes
const { toggleModals, toggleActiveQuiz } = quizzesMutationsTypes

const { getQuizz } = quizzesActionsTypes
export default {
  neme: 'BhConfirm',
  data() {
    return {
      message: 'Confirmed!',
    }
  },

  created() {
    const quizzName = 'logged-in'

    const searchParams = new URLSearchParams(window.location.search)
    const token = searchParams.get('token')
    const userId = searchParams.get('userId')

    this.$store
      .dispatch(checkConfirmByMail, { token, userId })
      .then(res => {
        this.message = res.message
        if (res.success) {
          this.$store.commit(isFirstLogin, true)
          this.$router.push('/').catch(() => {})
          this.$store.commit(toggleModals, true)
          this.getQuizzes(quizzName)
          /* this.$store.dispatch(getCurrentUser) */
        }
      })
      .catch(Error)
  },
  methods: {
    getQuizzes(quizzName) {
      this.$store.dispatch(getQuizz, quizzName).then(({ items }) => {
        this.$store.commit(toggleActiveQuiz, items[0].quizzID)
      })
    },
  },
}
</script>

<style scoped>
h1 {
  width: 75%;
  margin: 5% auto;
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  color: #583b75;
}
</style>
